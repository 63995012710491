import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import InsideBanner from '../components/insidebanner'
import { Container, Header, Grid } from 'semantic-ui-react'

const ContactUs = () => (
  <Layout>
    <SEO title="Contact Us" />
    <InsideBanner headline="Contact Us" src="/img/banners/Eisenreich.Phillies-e1523836613569.jpg" />
    <Container style={{ marginBottom: '35px' }} vertical>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5} style={{ borderRight: 'solid 1px #d7d7d7'}}>
            <Header as='h2' content="Contact Information" />
            <p>
              Jim Eisenreich Foundation<br/>
              Post Office Box 953<br/>
              Blue Springs, MO 64013
            </p>
            <p><a href="mailto:Foundation@Tourettes.org">Foundation@Tourettes.org</a></p>
            <Header as='h2' content="Join Our Mailing List" />
            <p>Want to be notified of events Jim is participating in your part of the country or keep up with news about Tourette Syndrome?</p>
            <p><a href="mailto:contactus@tourettes.org">Send us a request</a> to be added to our email list and tell us where you live.</p>
          </Grid.Column>
          <Grid.Column width={11}>
            <Header as='h2' content="Board of Directors" />
            <p><strong>Jim Eisenreich</strong> is the founder of the Jim Eisenreich Foundation and continues to be actively involved in its day-to-day activities. His passion for helping children with Tourette Syndrome and motivating others to overcome obstacles in their life is contagious. </p>
            { /*<p><strong>Meryl Lin McKean</strong> joined Fox 4 in Kansas City in 1985 and is currently their health and medical reporter. This Emmy award winning reporter helps support the Foundation's outreach and communication efforts, drawing on her diverse knowledge of health and medical issues.</p>
            <p><strong>Dana McMillan</strong> is an accomplished early childhood educator and author who has delivered training to thousands of educators during her career. Dana supports the foundation in its curriculum development and outreach to the community.</p>
            <p><strong>Fran Thornton</strong> offers her administrative and organizaitonal expertise from her career with AT&amp;T to the Eisenreich Foundation to keep us all 'on the same page'. </p>
            <p><strong>Penny Williams</strong> is a Parent Educator with the Parent as Teachers program in the Blue Springs School District and understands first hand the challenges of dealing with children with Tourette Syndrome and its related disorders. Her passion for children and families drives her involvement with the Foundation. </p>
            <p><strong>Randy Williams</strong> has spent his career in corporate communications and is the Director of Community Relations for the Kansas City Art Institute. He brings his corporate foundation experience to the Jim Eisenreich Foundation to help with program and strategy development as well as community outreach.</p> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Layout>
)

export default ContactUs
